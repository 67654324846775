
import sentryInit from "./components/sentry";

// Import stylesheets
import '../styles/app.scss';

// Initialize Sentry
sentryInit();

function popwindow(xsize,ysize,url,align) {
    var wWidth, wHeight, wLeft, wTop;
    wWidth = xsize;
    if(ysize>0) {
        wHeight = ysize;
    } else {
        wHeight = wWidth*.75;
    }
    if(align=='center') {
        wLeft = (screen.width-wWidth)/2;
        wTop = (screen.height-wHeight)/2;
        var Scherm = window.open(url, '_blank', 'scrollbars=yes,width='+wWidth+',height='+wHeight+',left='+wLeft+',top='+wTop);
    } else {
        var Scherm = window.open(url, '_blank', 'scrollbars=yes,width='+wWidth+',height='+wHeight);
    }
    Scherm.focus();
}

$( document ).ready(function() {

	if (window.document.location.pathname === '/stats') {
		throw new Error('_notice: This is a test error');
	}

    // mobile menu
    $(".mobile_hambuger").click(function() {
        $("#menubalk").slideToggle();
    });


    // pasten niet toestaan in veld 'Herhaal e-mailadres'
    $("input[type=email][name='input[2]']").bind("paste",function(e) {
        e.preventDefault();
    });

    $(".renner_tr").click(function(event) {

		// Don't do anything when clicking on a pcs link
		if (true === event.target.classList.contains('pcs-link')) {
			return;
		}

        // aantal_gekozen_fixed tonen na klikken op een renner
        $(".aantal_gekozen_fixed").css("display","block");

        if($(event.target).closest('input[type="checkbox"]').length > 0) {

        } else {
            if($("#renner_"+$(this).data("renner_id")).is(":checked")) {
                $("#renner_"+$(this).data("renner_id")).prop("checked",false);
            } else {
                $("#renner_"+$(this).data("renner_id")).prop("checked",true);
            }
        }

        if($("#renner_"+$(this).data("renner_id")).is(":checked")) {
            $(this).removeClass("renner_tr_not_clicked");
        } else {
            $(this).addClass("renner_tr_not_clicked");
        }

        toon_juiste_teksten($(this).data("renner_id"));

    });

    // onload: juiste tekst tonen
    if($(".renner_tr").length!==0) {
        toon_juiste_teksten(0);

        $(".renner_tr").addClass("renner_tr_not_clicked");
    }

    // wtform: prevent spambots: change wtform_botcheck
    if ($("form.wtform_prevent_spambots").length!==0) {
        $("input[name=wtform_botcheck]").val("checked3283847");
    }

    if (window.NotificationIsSupported === true && window.useServiceWorker === true && 'serviceWorker' in window.navigator) {

        // Retrieve Firebase Messaging object.
        const messaging = window.firebase.messaging();

        messaging.usePublicVapidKey("BJ8RagbT_miuEKKhRjmGNmO5VP35O0H_EPVnN_UYzsLgUwZaElb9HSB_JauycZ4vB6jcTK2_OGJ791k9Upi1q18");

        getNotificationToken(messaging, false);

		// Get contents of notification_set_for_year cookie
		var notification_set_for_year = getCookie("notification_set_for_year");

		// subscribe to the topics of the current year
        var hasPermission = window.Notification.permission;
        if (hasPermission === 'granted' && notification_set_for_year !== window.yearVersion) {

            messaging.getToken().then(function(currentToken) {
                if (currentToken) {
                    $.getJSON( "rpc_json.php?t=save-notification-token&token=" + currentToken, function(data) {
						if (typeof data.okay != "undefined") {
							// set cookie in javascript that expires in 6 months
							var d = new Date();
							d.setTime(d.getTime() + (6*30*24*60*60*1000));
							var expires = "expires="+d.toUTCString();
							document.cookie = "notification_set_for_year=" + window.yearVersion + "; " + expires;
						}
                    });
                }
            }).catch(function(err) {

            });
        }

        // button to ask for notification permission
        $(".button--notifications").click(function() {

            messaging.requestPermission().then(function() {
                // console.log('Notification permission granted.');
                // TODO(developer): Retrieve an Instance ID token for use with FCM.
                getNotificationToken(messaging, true);
                // ...
            }).catch(function(err) {
                // console.log('Unable to get permission to notify.', err);
            });
        });

    } else {
        $("#notificationbutton").hide();
        $("#notificationbutton_confirmed").hide();
    }

    // Show conditions popup
    $('[data-role="show-conditions"]').click(function(event) {
        event.preventDefault();
        popwindow(700,500,'/voorwaarden.php',true);
    });

    // Click on #printbutton to print the page
    $('#printbutton').click(function(event) {
        event.preventDefault();
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			'event' : 'print_participants_list'
		});
        window.print();
    });

    // Click on data-role="popwindow" to open a popup
    $('[data-role="popwindow"]').click(function(event) {
        event.preventDefault();
        let width = $(this).data('width');
        let height = $(this).data('height');
        let url = $(this).data('url');
        popwindow(width, height, url);
    });
});

function getCookie(name) {
	const value = `; ${document.cookie}`;
	const parts = value.split(`; ${name}=`);
	if (parts.length === 2) return parts.pop().split(';').shift();
}

function getNotificationToken(messaging, firstTime) {
    // Get Instance ID token. Initially this makes a network call, once retrieved
    // subsequent calls to getToken will return from cache.

	messaging.getToken().then(function(currentToken) {
        if (currentToken) {
            if (firstTime) {
                sendTokenToServer(currentToken);
                updateUIForPushEnabled(currentToken);
            } else {
				$("#notificationbutton").hide();
				$("#notificationbutton_confirmed").hide();
            }
        } else {
            // Show permission request.
            // console.log('No Instance ID token available. Request permission to generate one.');
            // Show permission UI.
            updateUIForPushPermissionRequired();
            setTokenIsSentToServer(false);
        }
    }).catch(function(err) {

		if (err.code === "messaging/token-unsubscribe-failed") {
			getNotificationToken(messaging, firstTime);
		} else {
			// console.log('An error occurred while retrieving token. ', err);
			setTokenIsSentToServer(false);

			$("#notificationbutton").hide();
			$("#notificationbutton_confirmed").hide();
		}
    });
}

function updateUIForPushEnabled(token) {
    $("#notificationbutton").slideUp();
    $("#notificationbutton_confirmed").slideDown();

	// Add event to dataLayer
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({
		'event' : 'subscribe_to_notifications'
	});
}

function updateUIForPushPermissionRequired() {
    $("#notificationbutton").css("visibility", "visible");
    $("#notificationbutton").show();
    $("#notificationbutton_confirmed").hide();
}

function sendTokenToServer(token) {

    // console.log('send token ' + token + ' to server');

    $.getJSON( "rpc_json.php?t=save-notification-token&token=" + token, function(data) {

    });

}

var tokenIsSentToServer = false;
function setTokenIsSentToServer(status) {
    tokenIsSentToServer = status;
}

function toon_juiste_teksten(waarde) {
    var totaal=0;
    var aantal_nog_te_selecteren=0;
    var len = document.frm.elements.length;
    for(var i=0;i<len;i++) {
        var e = document.frm.elements[i];
        if ((e.type == 'checkbox') && (e.checked) && (e.className!="ignorecounter")) {
            totaal+= 1;
        }
    }

    if(totaal>=12) {
        document.frm.opslaan1.disabled=0;
        document.frm.opslaan2.disabled=0;
        $(".aantal_gekozen_fixed").html("Sla je ploeg nu op.");
    } else {
        document.frm.opslaan1.disabled=1;
        document.frm.opslaan2.disabled=1;
        $(".aantal_gekozen_fixed").html('aantal nog te selecteren: <span class="aantal_nog_te_selecteren"></span>');

        aantal_nog_te_selecteren=12-totaal;
        $(".aantal_nog_te_selecteren").html(aantal_nog_te_selecteren);
    }

    if(waarde==0 && (totaal==0 ||totaal==12)) {
        document.getElementById('laag13').style.visibility='visible';
    } else if(totaal>12) {
        if(waarde>0) {
            document.frm.elements['renner['+waarde+']'].checked=false;
            alert("Je kunt maximaal 12 renners selecteren.");
        }
    } else {
        if(waarde>0) {
            if(document.frm.elements['renner['+waarde+']'].checked==true) {
                $("#rennerbalk"+waarde).addClass("geselecteerderenner");
            } else {
                $("#rennerbalk"+waarde).removeClass("geselecteerderenner");
            }
        }

        for(var i=0;i<14;i++) {
            if(totaal==i) {
                document.getElementById('laag'+i).style.visibility='visible';
            } else {
                document.getElementById('laag'+i).style.visibility='hidden';
            }
        }
    }
}
