import * as Sentry from '@sentry/browser';

const sentryInit = () => {
	Sentry.init({
		dsn: window.SENTRY_DSN,
		environment: window.SENTRY_ENVIRONMENT,
		release: window.SENTRY_RELEASE,
		ignoreErrors: [
			"Non-Error exception captured",
			"Non-Error promise rejection captured",
			/msDiscoverChatAvailable/i,
			/SymBrowser_ModifyWindowOpenWithTarget/i,
			/SymBrowser_ModifyAnchorTagWithTarget/i,
			/Blocked a frame with origin.*from accessing a cross-origin frame/i,
		],
		beforeSend(event, hint) {
			// If error starts with "_notice:" change SeverityLevel to "info"
			if (event.exception && event.exception.values && event.exception.values[0] && event.exception.values[0].value && event.exception.values[0].value.startsWith('_notice:')) {
				event.level = 'info';
			}

			return event;
		},
		denyUrls: [
			// Google Maps
			/maps\.googleapis\.com/i,

			// Google Analytics
			/www\.google-analytics\.com/i,

			// Doubleclick
			/doubleclick\.net/i,

			// Google AdSense
			/pagead2\.googlesyndication\.com/i,

			// LiveChat
			/cdn\.livechatinc\.com\/tracking\.js/i,

			// Hotjar
			/static\.hotjar\.com/i,

			// Squeezely
			/squeezely\.tech\/tracker/i,

			// Browser extensions
			/^chrome-extension:\/\//i,
			/^chrome:\/\//i,
			/extensions\//i,
			/safari-extension/i,
			/safari-web-extension/i,
			/webkit-masked-url/i,

			// Local files
			/^file:\/\//i,
		],
	});
};

export default sentryInit;
